/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_VERSION } from './constants';
export default function createMigrate(migrations, config) {
    const { debug } = config || {};
    return function (state, currentVersion) {
        if (!state) {
            if (process.env.NODE_ENV !== 'production' && debug)
                console.log('reduxjs-toolkit-persist: no inbound state, skipping migration');
            return Promise.resolve(undefined);
        }
        const inboundVersion = state._persist && state._persist.version !== undefined
            ? state._persist.version
            : DEFAULT_VERSION;
        if (inboundVersion === currentVersion) {
            if (process.env.NODE_ENV !== 'production' && debug)
                console.log('reduxjs-toolkit-persist: versions match, noop migration');
            return Promise.resolve(state);
        }
        if (inboundVersion > currentVersion) {
            if (process.env.NODE_ENV !== 'production')
                console.error('reduxjs-toolkit-persist: downgrading version is not supported');
            return Promise.resolve(state);
        }
        const migrationKeys = Object.keys(migrations)
            .map(ver => parseInt(ver))
            .filter(key => currentVersion >= key && key > inboundVersion)
            .sort((a, b) => a - b);
        if (process.env.NODE_ENV !== 'production' && debug)
            console.log('reduxjs-toolkit-persist: migrationKeys', migrationKeys);
        try {
            const migratedState = migrationKeys.reduce((state, versionKey) => {
                if (process.env.NODE_ENV !== 'production' && debug)
                    console.log('reduxjs-toolkit-persist: running migration for versionKey', versionKey);
                return migrations[versionKey](state);
            }, state);
            return Promise.resolve(migratedState);
        }
        catch (err) {
            return Promise.reject(err);
        }
    };
}
